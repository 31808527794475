<template>
  <v-container fluid class="pa-0  fill-height">
    <transition appear name="fadeGroup" mode="out-in">
      <v-row v-if="author" class="ma-0 grey lighten-3" no-gutters>
        <v-col cols="12" md="4">
          <v-img gradient="to top, rgba(0, 0, 0, .0), rgba(0, 0, 0, .0)" :src="author.imageSrc" />
        </v-col>
        <v-col class="d-block d-md-flex align-center pa-6" cols="12" md="8">
          <v-responsive class="mx-auto px-3 px-md-12">
            <h1
              style="color: black; white-space: pre-line"
              class="mb-6"
              :class="$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1'"
              v-text="`${author.firstName} ${author.lastName}`"
            />
            <br />
            <p class="text-justify" style="white-space: pre-line" v-text="author.description"></p>
            <hr />
            <template v-if="listProducts.length">
              <v-card-text v-for="{ title, data, path } in listProducts" :key="title">
                <h4 v-if="data.length">{{ title }}</h4>
                <router-link
                  v-for="(item, index) in data"
                  :key="item.id"
                  tag="span"
                  class="cursorPointer d-flex"
                  :to="`${path}${item.id}`"
                  v-text="`${index + 1}. ${item.name}`"
                />
              </v-card-text>
            </template>
          </v-responsive>
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Author',
  metaInfo() {
    if (this.author) {
      return {
        title: `${this.author.firstName} ${this.author.lastName}`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `${this.author.shortDescription}`
          }
        ]
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      listAuthors: state => state.shared.listAuthors,
      artTherapyList: state => state.artTherapy.artTherapyList,
      listCalendars: state => state.studios.listCalendars,
      listStudios: state => state.studios.listStudios
    }),
    author() {
      return this.$store.getters.author(this.id)
    },
    listProducts() {
      let list = []
      list.push({
        title: 'Студии:',
        data: this.filterProducts(this.listStudios),
        path: '/studios/preview/'
      })
      list.push({
        title: 'Нейрографика:',
        data: this.filterProducts(this.artTherapyList),
        path: '/artTherapyDescription/'
      })
      list.push({
        title: 'Календарь:',
        data: this.filterProducts(this.listCalendars),
        path: '/calendar/preview/'
      })
      return list
    }
  },
  created() {
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
    if (!this.listCalendars.length) this.$store.dispatch('getListStudios', 'calendars')
    if (!this.artTherapyList.length) this.$store.dispatch('getArtTherapy')
    if (!this.listStudios.length) this.$store.dispatch('getListStudios', 'studios')
  },
  methods: {
    filterProducts(list) {
      let localList = []
      this.author.products.forEach(product => {
        let element = list.find(item => item.id === product && !item.draft)
        if (element) {
          localList.push({
            name: element.studioName,
            id: element.id
          })
        }
      })
      return localList
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
